import React, { Component } from 'react';
import '../../assets/css/main/footer.scss'
import { createHashHistory } from 'history';
const history = createHashHistory();

class Resolve extends Component {
  constructor(props) {
    super(props)
    this.state = {
      nowPage: history.location.pathname === '/' ? '/bgtWeb' : history.location.pathname,
      list: [
        {
          title: '首页',
          con: [],
          path: '/bgtWeb'
        }, {
          title: '产品&服务',
          con: ['金融科技', '智能园区', '软件定制开发'],
          path: '/bgtWeb/product'
        }, {
          title: '解决问题',
          con: ['解决问题'],
          path: '/bgtWeb/resolve'
        }, {
          title: '伙伴&案例',
          con: ['小程序开发', '微商城开发', '网页定制开发', '公众号开发', 'APP软件定制开发'],
          path: '/bgtWeb/example'
        }, {
          title: '关于我们',
          con: ['公司介绍', '我们的团队', '行业动态', '联系我们'],
          path: '/bgtWeb/about'
        }
      ]
    }
  }
  render() {
    const style = {
      color: '#fff',
    };
    return (
      <div className="footer">
        <div className="footer_t">
          <ul className="container d-flex justify-content-around actDom" data-actcls="fadeInUp">
            {
              this.state.list.map((item, index) => {
                return (
                  <li key={item.title + index} onClick={ () => { this.props.goPage(item.path) } }>
                    <b className="fz-16 p-1 r">{item.title}</b>
                    {
                      item.con.map((inItem, inIndex) => {
                        return (
                          <p key={inItem + inIndex}  className="fz-14 p-1">{ inItem }</p>
                        )
                      })
                    }
                  </li>
                )
              })
            }
          </ul>
        </div>
        <div className="footer_b">
          <div className="container d-flex align-items-center h-100 actDom" data-actcls="fadeInUp">
            <img src={require("../../assets/img/logo.png")} alt="" />
            <div>
              <p className="fz-14">TEL: 0571-87959553</p>
              <p className="fz-14">EMALL: zpx@baguatan.com</p>
              <p className="fz-14">
                <a href="https://beian.miit.gov.cn/" style={style} target="_blank">浙ICP备14013300号-1</a>
              </p>
            </div>
            <img className="qrcode" src={require("../../assets/img/qrcode.png")} alt="" />
          </div>
        </div>
      </div>
    )
  }
}

export default Resolve
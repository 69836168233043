import React, { Component } from 'react';
import '../assets/css/resolve.scss'

class Resolve extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  goPage(path) {
    this.props.history.push(path)
    document.body.scrollTop = document.documentElement.scrollTop = 0
  }
  render() {
    return (
      <div className="resolve">
        <div className="banner">
          <img src={require('../assets/img/resolve/solve-banner.png')} alt="" />
          <div className="banner_conBox pt-5">
            <div className="container fz-36 pt-5 actDom" data-actcls="fadeInUp">
              集合公司内外优势资源，为客户提供各种需求的统一的，专业的，标准的解决方案。
            </div>
          </div>
        </div>

        {/* 企业经营问题 */}
        <div className="container mt-4">
          <div className="row d-flex justify-content-between align-items-center">
              <div className="col-12 pr-0 col-md-6 col-lg-6 pr-md-5">
                <img className="w-100 actDom" src='' data-src={require('../assets/img/resolve/Mask@2x.png')} data-actcls="bounceInLeft"  alt="" />
              </div>
              
              <div className="text-muted col-12 col-md-6 col-lg-6 align-self-start">
                <div className="actDom" data-actcls="bounceInRight">
                  <h6 className="fz-30 mb-3 pl-3 about_title1">数字化解决方案</h6>
                  <p className="fz-18">
                  公司自主研发引擎级的开发框架——星数引擎,为客户提供从数字化系统提供,数字化定制方案的交付,到数字化后数据资产等等的解决方案。
                  </p>
                </div>
                <div className="actDom" data-actcls="bounceInRight">
                  <h6 className="fz-30 mb-3 pl-3 about_title1">行业解决方案</h6>
                  <p className="fz-18">
                  利用星数引擎,为客户快速解决行业解决方案,例如,融媒体行业的应用管理方案,在线商城,以及社区经济等领域的合作方案等,为客户快捷提供盈利框架。
                  </p>
                </div>
                <div className="actDom" data-actcls="bounceInRight" style={{ animationDelay: '.3s' }}>
                  <h6 className="fz-30 mb-3 pl-3 about_title1" data-actcls="fadeInUp">售后解决方案</h6>
                  <p className="fz-18">
                  十年来,我们与客户相处融恰,以开放的姿态,与客户构筑一个和谐的交流氛围,处于不同意见时默契认可灰度空间的存在,并且致力于解决问题。
                  </p>
                </div>
              </div>
          </div>

          {/* 项目流程 */}
        <div className="container rl_stepbox">
          <h6 className="fz-36 bigTitle actDom" data-actcls="fadeInUp">数字化项目定制流程</h6>
          <p className="fz-20 text-muted text-center actDom" data-actcls="fadeInUp">优化产品流程，增强产品的易用性，提高使用者的工作效率，科学地组织生产。</p>

          <ul className="ex_pro row">
            <li className="col-12 col-md-6 actDom" data-actcls="bounceInLeft">
              <div className="w-100">
                <h6 className="fz-22">需求沟通</h6>
                <p className="fz-18">倾听客户需求，了解用户使用环境和操作流程</p>
              </div>
              
              <img src={require("../assets/img/example/xuqiu@2x.png")} alt="" />
              <div className="ex_pro_count"><h6 className="fz-80">1</h6></div>
            </li>
            <li className="col-12 col-md-6 actDom" data-actcls="bounceInRight">
              <img src={require("../assets/img/example/qianduan@2x.png")} alt="" />
              <div className="ex_pro_count"><h6 className="fz-80">2</h6></div>
              <div className="w-100">
                <h6 className="fz-22">前端制作</h6>
                <p className="fz-18">HTML5、IOS、Android实现页面的动态展示</p>
              </div>
            </li>
            <li className="col-12 col-md-6 actDom tDely-01" data-actcls="bounceInLeft">
              <div className="w-100">
                <h6 className="fz-22">交互设计</h6>
                <p className="fz-18">头脑风暴,提炼关键词,交互情景模拟，原型图绘制</p>
              </div>
              <img src={require("../assets/img/example/jishu@2x.png")} alt="" />
              <div className="ex_pro_count"><h6 className="fz-80">3</h6></div>
            </li>
            <li className="col-12 col-md-6 actDom tDely-01" data-actcls="bounceInRight">
              <img src={require("../assets/img/example/ceshi@2x.png")} alt="" />
              <div className="ex_pro_count"><h6 className="fz-80">4</h6></div>
              <div className="w-100">
                <h6 className="fz-22">技术开发</h6>
                <p className="fz-18">移动应用数据对接及开发</p>
              </div>
            </li>
            <li className="col-12 col-md-6 actDom tDely-02" data-actcls="bounceInLeft">
              <div className="w-100">
                <h6 className="fz-22">视觉创意</h6>
                <p className="fz-18">风格、色彩及元素设定，结构和布局规范确认</p>
              </div>
              <img src={require("../assets/img/example/jiaohu@2x.png")} alt="" />
              <div className="ex_pro_count"><h6 className="fz-80">5</h6></div>
            </li>
            <li className="col-12 col-md-6 actDom tDely-02" data-actcls="bounceInRight">
              <img src={require("../assets/img/example/shijue@2x.png")} alt="" />
              <div className="ex_pro_count"><h6 className="fz-80">6</h6></div>
              <div className="w-100">
                <h6 className="fz-22">测试反馈</h6>
                <p className="fz-18">细节修改,问题调试,规范完善</p>
              </div>
            </li>
          </ul>
          </div>
          </div>
          

          {/* 系统及数据安全维护方案 */}
        <div className="position-relative pb-5">
          <img src={require('../assets/img/resolve/solve.png')} alt="" className="rl_system_bg" />
          {/* <div className="rl_system_con"> */}
            <div className='container rl_system_con'>
              <div className="rl_system_title fz-36 pt-5 actDom" data-actcls="fadeInUp">系统及数据安全维护方案</div>
              <p className="fz-20 text-center py-3 actDom" data-actcls="fadeInUp">根据客户项目不同，对于安全采用不同的等级保护，采用不同系统及数据安全保障措施。</p>
              <div className="row d-flex justify-content-between align-items-center actDom" data-lazypic="1" data-actcls="fadeInUp">
                
                <ul className="col-12 col-md-7 pt-5">
                  {/* <li className="fz-20"></li> */}
                  <li className="fz-18"><span></span>技术上防范，在代码规范上，进行基础的病毒防范机制。建议用户在阿里云等云数据服务商提供的免费或者收费（由业主购买）的安全措施，进行防护。</li>
                  <li className="fz-18"><span></span>管理上防范，通过公司签署保密协议等，然后对代码等进行分布式部署与合成模式，确保系统安全性。</li>
                  <li className="fz-18"><span></span>通过远程维护，现场维护，以及驻场维护等方式，对业主进行数据安全的不同级别的响应。</li>
                  <li className="fz-18"><span></span>推荐优秀的防病毒/攻击的安全厂商，进行模拟攻击，并做测试。</li>
                </ul>

                <div className="col-12 col-md-5 pt-5">
                  <img className="w-100" src='' data-src={require('../assets/img/resolve/solve-photo.png')}  alt="" />
                </div>
              </div>
            </div>
          {/* </div> */}
        </div>
          
        {/* 智慧运营 */}
        <div className="container">
          <h6 className="fz-36 bigTitle actDom" data-actcls="fadeInUp">售后服务规范</h6>
          <div className="fz-18 text-muted text-md-center">
            <p className="rl_sttext mx-auto actDom" data-actcls="fadeInUp">良好的售后服务不仅为客户解决了项目运营的后顾之忧，也为公司树立优秀的技术品牌。</p>
          </div>
          <ul className="row rl_stlist pb-5 actDom mb-5" data-actcls="fadeInUp">

            <li className="mt-5 col-12 text-center" onClick={ () => this.goPage('/bgtWeb/product') }>
              <div className="rl_stlist_con row">
                <div className="order-0 order-md-2 col-md-5 col-12">
                  <img src={require('../assets/img/resolve/jianse@2x.png')} alt="" />
                  <h6 className="fz-20 pt-1 pb-3">售后服务标准</h6>
                </div>
                <div className="order-1 col-md-7 col-12">
                  <div className="rl_stlist_text fz-16 mb-3">
                    <span></span>免费的售后服务时间为半年
                  </div>
                  <div className="rl_stlist_text fz-16 mb-3">
                    <span></span>在售后服务期的前两周，我方将指派派工作人员协同甲方使用该软件，进行培训与在线、远程指导
                  </div>
                  <div className="rl_stlist_text fz-16 mb-3">
                    <span></span>在售后服务期内，乙方保证在出现应用系统故障时24小时内积极响应，解决故障，遇有特殊情况双方协商
                  </div>
                  <div className="rl_stlist_text fz-16 mb-3">
                    <span></span>响应时间：接到用户障碍申告与售后请求后，本司工作人员通过即时通讯工具进行沟通。根据申告内容紧急度，启动不同的响应级别机制。突发重大问题，在2个小时内响应，一般在24小时内响应并进行解决。
                  </div>
                </div>
                
              </div>
            </li>

            <li className="mt-5 col-12 text-center" onClick={ () => this.goPage('/bgtWeb/product') }>
              <div className="rl_stlist_con row">
                <div className="col-md-4 col-12">
                  <img src={require('../assets/img/resolve/gongju@2x.png')} alt="" />
                  <h6 className="fz-20 pt-1 pb-3">售后内容（免费服务期内）</h6>
                </div>
                <div className="order-1  col-md-7 col-12">
                  <div className="rl_stlist_text fz-16 mb-3">
                    <span></span>每月常规系统巡检，提供业主运行状况报告
                  </div>
                  <div className="rl_stlist_text fz-14 mb-3">
                    <span></span>临时障碍预警与告知，并提供解决方案建议
                  </div>
                  <div className="rl_stlist_text fz-14 mb-3">
                    <span></span>数据库备份，15天一次
                  </div>
                  <div className="rl_stlist_text fz-14 mb-3">
                    <span></span>系统bug修复，测试、升级（限于第三方平台升级，比如微信公号，小程序相关规则修改后的升级）、基础运维
                  </div>
                  <div className="rl_stlist_text fz-14 mb-3">
                    <span></span>基础病毒与攻击的防护，在阿里云等云杀毒软件出现问题时，可以即时修复系统漏洞。（常规攻击不在维护范围内，例如大规模的通常类攻击）
                  </div>
                  <div className="rl_stlist_text fz-14 mb-3">
                    <span></span>免费协助甲方进行知识产权申报，提供技术协助
                  </div>
                </div>
                
              </div>
            </li>
            <li className="mt-5 col-12 text-center" onClick={ () => this.goPage('/bgtWeb/product') }>
              <div className="rl_stlist_con row">
                <div className="order-0 order-md-2 col-md-5 col-12">
                  <img src={require('../assets/img/resolve/hexin@2x.png')} alt="" />
                  <h6 className="fz-18 pt-1 pb-3">客服的交流方式</h6>
                </div>
                <div className="order-1 col-md-7 col-12">
                  <div className="rl_stlist_text fz-14 mb-3">
                    <span></span>电话： 0571-87959553
                  </div>
                  <div className="rl_stlist_text fz-14 mb-3">
                    <span></span>邮件：bgt@baguatan.cn
                  </div>
                  <div className="rl_stlist_text fz-14 mb-3">
                    <span></span>微信公号：（二维码）
                  </div>
                  <div className="rl_stlist_text fz-14 mb-3">
                    <span></span>微信/QQ群
                  </div>
                  <div className="rl_stlist_text fz-14 mb-3">
                    <span></span>项目的售后支持论坛：bbs.bagutan.cn
                  </div>
                </div>
                
              </div>
            </li>
            <li className="mt-5 col-12 text-center" onClick={ () => this.goPage('/bgtWeb/product') }>
              <div className="rl_stlist_con row">
                <div className="col-md-4 col-12">
                  <img src={require('../assets/img/resolve/xinxi@2x.png')} alt="" />
                  <h6 className="fz-18 pt-1 pb-3">培训与移交</h6>
                </div>
                <div className="col-md-8 col-12">
                  <div className="rl_stlist_text fz-14 mb-3">
                    <span></span>项目建设完成之后，我方将对受托方使用人员进行培训，交付使用/操作手册；或者现场培训等
                  </div>
                  <div className="rl_stlist_text fz-14 mb-3">
                    <span></span>在浙江省范围内，我方对业主提供一次免费的现场培训。超出之后，按照产生的差旅费实报实销，及上门费1000元/次
                  </div>
                  <div className="rl_stlist_text fz-14 mb-3">
                    <span></span>移交代码与相关权限：在测试完成之后，根据业主需求，将代码全部移交给业主方。权限包括各项密码等
                  </div>
                  <div className="rl_stlist_text fz-14 mb-3">
                    <span></span>技术培训：移交之前后，可以根据甲方的需求，为其培训相关的技术团队，在移交之后能够由甲方团队顺利接手，并做二次开发
                  </div>
                </div>
                
              </div>
            </li>
          </ul>
        </div>

        
        


        
      </div>
    )
  }
}

export default Resolve
import React, { Component } from 'react';
import '../assets/css/about.scss'
import $ from 'jquery'
class About extends Component {
  constructor(props) {
    super(props)
    this.state = {
      wegood: [],
      count1: 0,
      count2: 0,
      count3: 0,
      nowtitle: '',
      timelist: [],
      imglist: [
        require('../assets/img/about/team-two.png'),
        require('../assets/img/about/team-three.png'),
        require('../assets/img/about/team-four.png'),
        require('../assets/img/about/team-five.png'),
        require('../assets/img/about/WechatIMG390.jpg'),
        require('../assets/img/about/WechatIMG391.jpg'),
        require('../assets/img/about/WechatIMG393.jpg'),
      ],
      translateX: 0
    }
  }
  goodhover(index) {
    let wegood = []
    wegood[index] = true
    this.setState({
      wegood
    })
  }
  goodleave() {
    this.setState({
      wegood: []
    })
  }
  componentDidMount() {
    // this.autoswiper()
    this.scrollFun()
    this.changeSwiper()
    if (this.countTimer) {
      clearInterval(this.countTimer)
    }
    // 获取轨迹数据
    fetch("https://chanfu.baguatan.cn/officialWebsite/timelineImage/getTimelineImage", {
          method: "GET",
          headers: {
              "Content-Type": "application/json",
          },
        }).then(function(res) {
          if (res.status === 200) {
              return res.json()
          } else {
              return Promise.reject(res.json())
          }
        }).then((res)=> {
          if (res.code === 0) {
            this.setState({
              timelist:res.data,
              nowtitle:res.data.length?res.data[0].year+'年':''
            })
          }
        }).catch((err)=> {
          console.log(err);
        })
  } 
  changeSwiper() {
    $('#carouselExampleIndicators').on('slid.bs.carousel', (val) => {
      // do something…
      this.setState({
        nowtitle: this.state.timelist[val.to].year
      })
    })
  }
  scrollFun() {
    let container = document.querySelector('#container')
    let actDomNum = document.querySelector('.actDomNum')
    let num = 0
    let count1 = 0
    let count2 = 0
    let count3 = 0
    window.onscroll = () => {
      let wh = document.body.scrollTop || document.documentElement.scrollTop
      if (wh + window.innerHeight > container.offsetTop && num < 1) {
        num++
        this.addMap()
      }
      // 动态数字变大
      if (actDomNum.offsetTop <= wh + window.innerHeight && count1 === 0) {
        count1++
        count2++
        count3++
        this.countTimer = setInterval(() => {
          count1 < 20 && count1 ++
          count2 < 200 && count2 ++
          count3 < 1000 && count3 ++
          this.setState({
            count1, count2, count3
          })
          if (count2 >= 200) {
            this.setState({
              count2: '200+'
            })
          }
          if (count3 >= 1000) {
            this.setState({
              count3: '1000+'
            })
            clearInterval(this.countTimer)
          }
          
        }, 20)
      }
    }
  }
  goPage(path) {
    this.props.history.push(path)
    document.body.scrollTop = document.documentElement.scrollTop = 0
  }
  // autoswiper() {
  //   let aboutSwiper = document.getElementById('aboutSwiper')
  //   let oli = aboutSwiper.getElementsByTagName('li')
  //   this.interval = setInterval(() => {
  //     if (this.state.translateX === -parseInt(parseInt(this.getStyle(oli[0], 'width')) * oli.length / 2)) {
  //       this.setState({
  //         translateX: 0
  //       })
  //     } else {
  //       this.setState({
  //         translateX: this.state.translateX - .5
  //       })
  //     }
  //   }, 10)
  // }
  getStyle(obj,name){
    if (obj.currentStyle) {
      return obj.currentStyle[name];
    }else{
      return getComputedStyle(obj,false)[name];
    }
  }
  componentWillUnmount() {
    // clearInterval(this.interval)
    window.onscroll = null
  }
  
  // 高德地图
  addMap() {
    const point = [120.204338, 30.171396]
    let AMap = window.AMap
    let map = new AMap.Map('container', {
        zoom:18,//级别
      center: point,//中心点坐标
      // pitch:75, // 地图俯仰角度，有效范围 0 度- 83 度
      // viewMode:'3D' // 地图模式
    });
    AMap.plugin([
      'AMap.ToolBar',
      'AMap.Scale',
      'AMap.OverView',
      'AMap.MapType',
      'AMap.Geolocation',
  ], function(){
      // 在图面添加工具条控件，工具条控件集成了缩放、平移、定位等功能按钮在内的组合控件
      map.addControl(new AMap.ToolBar());
  
      // 在图面添加比例尺控件，展示地图在当前层级和纬度下的比例尺
      map.addControl(new AMap.Scale());
  
      // 在图面添加鹰眼控件，在地图右下角显示地图的缩略图
      map.addControl(new AMap.OverView({isOpen:true}));
     
      // 在图面添加类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
      map.addControl(new AMap.MapType());
     
      // 在图面添加定位控件，用来获取和展示用户主机所在的经纬度位置
      map.addControl(new AMap.Geolocation());
  });
    let marker = new AMap.Marker({
        position: point
    })
    map.add(marker);
    let infoWindow = new AMap.InfoWindow({
      anchor: 'middle-left',
      content:  `
        <div class="mappop">
          <h6>杭州巴瓜潭科技有限公司</h6>
          <p>热线</p>
          <a href="javascript:;">86-571-87959553</a>
          <p>邮箱</p>
          <div>zpx@baguatan.cn</div>
          <p>地址</p>
          <div>杭州市江虹南路316号京安大厦3幢6楼605室</div>
          <hr/>
          <div>欢迎咨询我们</div>
        <div>
      `
     //使用默认信息窗体框样式，显示信息内容
    })
    infoWindow.open(map, point);
    marker.on('click', function() {
      infoWindow.open(map, point);
    });
  }
  render() {
    return (
      <div className="ablout">
        
        <div className="banner">
          <img src={require('../assets/img/about/company-banner.png')} alt="" />
          <div className="banner_conBox pt-5">
            <div className="banner_con pt-5 actDom" data-actcls="fadeInUp">
              <h6 className="fz-50 banner_con_title">巴瓜潭</h6>
              <p className="fz-36 banner_con_text">来自八卦田，一直专注于产业数字化,数字产业化</p>
            </div>
          </div>
        </div>
        {/* 公司简介 */}
        <div className="container mt-4">
          <div className="row d-flex justify-content-between align-items-center">
            <div className="col-12 pr-0 col-md-6 pr-md-5">
              <img className="w-100 actDom" src='' data-src={require('../assets/img/about/company-photos.png')} data-actcls="bounceInLeft"  alt="" />
            </div>
            
            <div className="text-muted col-12 col-md-6 align-self-start">
              <div className="actDom" data-actcls="bounceInRight">
                <h6 className="fz-30 mb-3 pl-3 about_title1">公司简介</h6>
                <p className="fz-18" style={{'textIndent': '2em'}}>
                杭州巴瓜潭科技有限公司，成立于2014年，公司专注数字化领域,从企业数字化,行业数字化,到产业数字化实践后,我们努力尝试着践行数字化带来的变革,目前向数字产业化延伸,包括数字化的产业运营服务,数字化的园区领域实践与运营延伸,以及数字化的营销服务等。
                </p>
                <p className="fz-18" style={{'textIndent': '2em'}}>
                目前在巴瓜潭数字化内核的基础上,涉足纵深领域,对外投资了数字化园区公司-搜雪网络,以及数字化内核研发公司-星数为来;以及电商公司-洽吉电商等,并且向地方市场进行落地,设立了开化巴瓜潭产业运营服务公司.打造一个数字化经营的生态企业集群,为客户提供多维度,立体化的能力和解决方案。
                </p>
              </div>
              <div className="actDom" data-actcls="bounceInRight" style={{ animationDelay: '.3s' }}>
                <h6 className="fz-30 mb-3 pl-3 about_title1" data-actcls="fadeInUp">企业价值观</h6>
                <p className="fz-18">
                正义，简单，踏实，靠谱
                </p>
                {/* <p className="fz-18">
                  为客户提供基于移动支付技术务。借助银行等第三方支付通道，提供融合支付的收费场景技术服务。
                </p> */}
              </div>
            </div>
          </div>

          <ul className="text-myblue d-flex justify-content-around mt-5 text-center actDom actDomNum" data-actcls="fadeInUp">
            <li>
              <div className="fz-70 font-weight-bold">{ this.state.count1 } </div>
              <p className="fz-22">年积累</p>
            </li>
            <li>
              <div className="fz-70 font-weight-bold">{ this.state.count2 } </div>
              <p className="fz-22">客户</p>
            </li>
            <li>
              <div className="fz-70 font-weight-bold">{ this.state.count3 } </div>
              <p className="fz-22">项目</p>
            </li>
          </ul>

          <h6 className="fz-36 bigTitle actDom" data-actcls="fadeInUp">矩阵式发展</h6>
          <p className="fz-20 text-muted text-center actDom pb-5" data-actcls="fadeInUp">数字化,园区,运营电商,营销以及产业化发展,生态公司聚是一个整体,分开是独立经营,互为资源,互为生态, 为客户提供系统性的解决方案.与一般的技术公司比,我们有产业,有运营的思维,与运营公司比,我们又有深厚的技术积淀。</p>
          <ul className="d-flex justify-content-around about_good row pt-5 pb-4">
            <li className="col-md-4 col-12 mb-5 actDom" data-actcls="fadeInUp" onMouseOver={ () => this.goodhover(0) } onMouseLeave={() => this.goodleave()}>
              <img className="w-50" src={ !this.state.wegood[0] ? require('../assets/img/about/jishu-default@2x.png') : require('../assets/img/about/jishu-hover@2x.png')} alt="" />
              <h6 className="fz-22">技术优势</h6>
              <p className="fz-18">团队拥有金融级技术开发和服务能力</p>
            </li>
            <li className="col-md-4 col-12 mb-5 actDom" data-actcls="fadeInUp" onMouseOver={ () => this.goodhover(1) } onMouseLeave={() => this.goodleave()}>
              <img className="w-50" src={ !this.state.wegood[1] ? require('../assets/img/about/hangye-default@2x.png') : require('../assets/img/about/hangye-hover@2x.png')} alt="" />
              <h6 className="fz-22">行业积累</h6>
              <p className="fz-18">有近十年对消费领域的理解和积累,并且做高效,高品质的技术呈现资源优势时化，智能化的办公环境</p>
            </li>
            <li className="col-md-4 col-12 mb-5 actDom" data-actcls="fadeInUp" onMouseOver={ () => this.goodhover(2) } onMouseLeave={() => this.goodleave()}>
              <img className="w-50" src={ !this.state.wegood[2] ? require('../assets/img/about/ziyuan-default@2x.png') : require('../assets/img/about/ziyuan-hover@2x.png')} alt="" />
              <h6 className="fz-22">资源优势</h6>
              <p className="fz-18">依托银行,用金融的思维为消费领域的经营赋予更多的模式和能力</p>
            </li>
          </ul>
        </div>



        {/* 轮播 */}
        <div className="about_swiper">
          <img src={require('../assets/img/about/company.png')} alt="" className="about_swiper_bg" />
          <div className="about_swiper_conBox">
            <div className="about_swiper_con">
              <ul id="aboutSwiper" style={{ transform:`translateX(${this.state.translateX}px)` }}>
                {
                  this.state.imglist.map((item, index) => {
                    return (
                      <li key={index}>
                        <img src={ item } alt=""/>
                      </li>
                    )
                  })
                }
                {
                  this.state.imglist.map((item, index) => {
                    return (
                      <li key={index}>
                        <img src={ item } alt=""/>
                      </li>
                    )
                  })
                }
                
              </ul>
            
            </div>
            <p className="fz-18 pt-3 pt-lg-5 text-center text-light">扎实沉淀,厚积薄发<br />向客户学习,向市场学习,快速响应，面对各种复杂的环境,求生存,谋发展,不断在行业实践中提升价值。</p>
          </div>
          
        </div>



        {/* 我们的轨迹 */}
        <div className="container mt-4 mb-5">
          <div className="about_route_title">
            <h6 className="fz-36 mb-1 actDom" data-actcls="fadeInUp">我们的轨迹</h6>
            <div className="fz-36">
              <span className="fz-50">{ this.state.nowtitle }</span>
            </div>
          </div>
          

          <div className="row d-flex justify-content-between about_route_con pt-5 pt-lg-0">
              <div id="carouselExampleIndicators" className="carousel slide actDom" data-ride="carousel" data-actcls="fadeInUp">
                <ol className="carousel-indicators d-none d-lg-flex">
                  {
                    this.state.timelist.map((item,index) =>{
                      return (
                        <li data-target="#carouselExampleIndicators" data-slide-to={index} key={index} className="li_indicators3">
                          <p className="fz-20">{item.year}年</p>
                        </li>
                      )
                    })
                  }
                  {/* {<li data-target="#carouselExampleIndicators" data-slide-to="7">
                  < p className="fz-20" style={{ left: '-10px' }}>未来</p>
                  </li>} */}
                </ol>
                
                  
              <div className="carousel-inner actDom" data-lazypic='1'  data-actcls="fadeInUp">
                {
                  this.state.timelist.map((item, index) => {
                    return (
                      <div className={ `carousel-item ${ index === 0 ? 'active' : '' }` } key={index}>
                        <img className="d-block w-100" src='' data-src={ item.imageAddress } alt="First slide" />
                        <div className='carousel-item_p fz-18'>
                          <p>{ item.description }</p>
                        </div>
                      </div>
                    )
                  })
                }
                </div>
                <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="sr-only">Next</span>
                </a>
              </div>
            </div>
            
        </div>

        {/* 地图 */}
        <div id="container"></div>
      </div>
    )
  }
}

export default About
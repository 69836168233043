import React, { Component } from 'react';
import '../../assets/css/main/menu.scss'

class Menu extends Component {
  backTop() {
    this.topTimer = setInterval(() => {
      let wh = document.body.scrollTop || document.documentElement.scrollTop
      if (wh < 10) {
        clearInterval(this.topTimer)
        this.topTimer = this.topTimer
      }
      if (document.documentElement.scrollTop < 20) {
        clearInterval()
      }
      document.body.scrollTop = document.documentElement.scrollTop = wh - 20
    }, 1);
    
  }
  call() {
    if(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)){
      window.location.href = "tel://0571-87959553"
    }
  }
  render() {
    return (
      <div className="rtMenu">
        {/* <div className="rtMenu_list"></div> */}
        <div className="rtMenu_list">
          <div className="rtMenu_list_con">
            <div className="rtMenu_list_call">0571-87959553</div>
            <i className="rtMenu_list_three"></i></div>
          </div>
          
        <div className="rtMenu_list">
          <div className="rtMenu_list_con">
            <img src={require('../../assets/img/menu/qrcoe.jpg')} alt=""/>
            <i className="rtMenu_list_three"></i>
          </div>
        </div>
        <div className="rtMenu_list" onClick={ () => this.backTop() }></div>
      </div>
    )
  }
}

export default Menu
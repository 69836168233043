import React, { Component } from 'react';
import '../assets/css/example.scss'
import ComFri from './main/comFri'
/* eslint-disable */
class Example extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tablist: ['APP软件', '微商城', '网站定制', '小程序', '公众号'],
      tabIndex: 0,
      exampleList: [
        [{
          url: require("../assets/img/example/app-one@2x.png"),
          title: '爱海宁'
        }, {
          url: require("../assets/img/example/app-two@2x.png"),
          title: '影遇'
        }], [{
          url: require("../assets/img/example/xiaochengxu-one@2.png"),
          title: '源品包包'
        }, {
          url: require("../assets/img/example/xiaochengxu-three@2.png"),
          title: '源品包包'
        }], [{
          url: require("../assets/img/example/web-one@2.png"),
          title: '液压'
        }], [{
          url: require("../assets/img/example/xiaochengxu-five@2.png"),
          title: '浙江省农业科创园'
        },{
          url: require("../assets/img/example/xiaochengxu-four@2.png"),
          title: '源品包包'
        },{
          url: require("../assets/img/example/xiaochengxu-one@2.png"),
          title: '汾酒竹叶青'
        },{
          url: require("../assets/img/example/xiaochengxu-three@2.png"),
          title: '牙小白'
        },{
          url: require("../assets/img/example/xiaochengxu-two@2.png"),
          title: 'ink-monster'
        }], [{
          url: require("../assets/img/example/gonghao-one@2.png"),
          title: '杭州动动帮'
        }, {
          url: require("../assets/img/example/gonghao-two@2.png"),
          title: '杭州市旅行社行业协会'
        }]
      ]
    }
  }
  chosetab(index) {
    this.setState({
      tabIndex: index
    })
    let actDomPic = document.querySelector('.actDomPic')
    actDomPic.classList.remove(actDomPic.getAttribute('data-actcls'))
    actDomPic.classList.remove('animated')
    setTimeout(() => {
      actDomPic.className = actDomPic.className + ' animated ' + actDomPic.getAttribute('data-actcls') 
      // actDomPic.classList.add(actDomPic.getAttribute('data-actcls'))
      // actDomPic.classList.add('animated')
      let imgs = actDomPic.getElementsByTagName('img')
      for (let i = 0; i < imgs.length; i++) {
        if (imgs[i].getAttribute('data-src')) {
          imgs[i].src = imgs[i].getAttribute('data-src')
        }
      }
    }, 0);
    
  }
  goPage(path) {
    this.props.history.push(path)
    document.body.scrollTop = document.documentElement.scrollTop = 0
  }
  render() {
    return (
      <div className="example">
        <div className="banner">
          <img src={require('../assets/img/example/case-banner.png')} alt="" />
          <div className="banner_conBox pt-5">
            <div className="banner_con pt-5 actDom" data-actcls="fadeInUp">
              <h6 className="fz-50 banner_con_title pb-5">我们珍惜合作之缘分</h6>
              <div className="container">
                <p className="fz-36 banner_con_text">能够跟客户进行美好而愉悦的沟通，都是巴瓜潭之幸，解决当下问题，立足长远是我们的合作主旨</p>
              </div>
            </div>
          </div>
        </div>

        


        <ComFri />

        
        {/* 团队优势 */}
        <div className="position-relative pb-5">
          <img src={require('../assets/img/resolve/solve.png')} alt="" className="rl_system_bg" />
          <div className='container rl_system_con'>
            <div className="row d-flex justify-content-between align-items-center actDom" data-lazypic="1" data-actcls="fadeInUp">
              <div className="col-12 col-md-5 pt-5 cursor-pointer">
                <img className="w-100 " src='' data-src={require('../assets/img/example/case-photos.png')}  alt="" onClick={ () => this.goPage('/bgtWeb/about') } />
              </div>
              <ul className="col-12 col-md-7 pt-5">
                <li className="fz-36 font-weight-bold pb-2">合作的价值观</li>
                <li className="fz-18"><span></span>共赢，共融，共荣</li>
                <li className="fz-18"><span></span>认真对待项目，为自己的客户带来更好的用户体验</li>
                <li className="fz-18"><span></span>不挖坑，不套路，真诚相待。</li>
                <li className="fz-18"><span></span>努力建立双方共同的生意链</li>
              </ul>
            </div>
          </div>
        </div>
        

        {/* 案例展示 */}
        <div className="ex_show container">
          <h6 className="fz-36 bigTitle actDom" data-actcls="fadeInUp">经典案例</h6>
          <nav className="navbar navbar-expand-md navbar-light actDom" data-actcls="fadeInUp">
            <a className="navbar-brand d-md-none d-block" href="#">{ this.state.tablist[this.state.tabIndex] }</a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
              <div className="navbar-nav w-100 justify-content-between">
                {
                this.state.tablist.map((item, index) => {
                  return (
                    <a className={`nav-item nav-link ${this.state.tabIndex === index ? 'active' : ''}`} key={index} href="javascript:;" onClick={() => this.chosetab(index)}>{ item }</a>
                  )
                })
              }
              </div>
            </div>
          </nav>
          

          

          {/* 案例图片 */}
          <ul className="row pb-5 mb-5 ex_foot actDom actDomPic" data-actcls="fadeIn" data-lazypic="1">
            {
              this.state.exampleList[this.state.tabIndex].map((item, index) => {
                return (
                  <li className="col-4" key={index}>
                    <div className="ex_footpic mt-5">
                        <img className="w-100 rounded" src='' data-src={ item.url } alt=""/>
                      <div className="ex_footpicCon fz-22">{ item.title }</div>
                    </div>
                  </li>
                )
              })
            }
          </ul>
        </div>

        
      </div>
    )
  }
}

export default Example
import React, { Component } from 'react';

class Head extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pageList: [{
        title: '首页',
        page: '/bgtWeb'
      }, {
        title: '产品&服务',
        page: '/bgtWeb/product'
      }, {
        title: '解决方案',
        page: '/bgtWeb/resolve'
      }, {
        title: '伙伴&案例',
        page: '/bgtWeb/example'
      }, {
        title: '对外投资',
        page: 'dropdown',
        type: 'dropdown',
        chilren: [
          {
            title: '搜雪网络',
            type: 'href',
            href: 'https://www.souxue.cc/build/#/park'
          },
          {
            title: '星数为来',
            type: 'href',
            href: 'https://www.xsframe.cn/'
          },
          {
            title: '开化巴瓜潭',
            type: 'preview',
            modelId:'modelId1'
          },
          {
            title: '洽吉商城',
            type: 'preview',
            modelId:'modelId2'
          }
        ]
      }, {
        title: '关于我们',
        page: '/bgtWeb/about'
      }],
      tabOff: false,
      wtabOff: false,
    }
  }
  componentDidMount() {
    // console.log('头部加载')
    this.scroll()
    this.resize()
  }
  componentWillUpdate() {
    // console.log('头部刷新加载')
    this.props.headload()
    setTimeout(() => {
      this.scrollFun()
    }, 0);
  }
  scroll() {
    setTimeout(() => {
      this.scrollFun()
    }, 0);
    // 屏幕滚动导航栏
    window.addEventListener('scroll', () => {
      let wH = document.body.scrollTop || document.documentElement.scrollTop
      if (wH > 20 && !this.state.tabOff && !this.state.wtabOff) {
        this.setState({
          tabOff: true,
        })
      } else if (wH < 10 && this.state.tabOff && !this.state.wtabOff) {
        this.setState({
          tabOff: false,
        })
      }
      this.scrollFun()
    })
    window.addEventListener('resize', () => {
      this.resize()
    })
  }
  // 判断屏幕宽度
  resize() {
    if (window.innerWidth < 600 && !this.state.wtabOff) {
      this.setState({
        wtabOff: true
      })
    } else if (window.innerWidth > 600 && this.state.wtabOff) {
      this.setState({
        wtabOff: false
      })
    }
  }
  // 屏幕滚动动画
  scrollFun() {
    let actDom = document.querySelectorAll('.actDom')
    let domTop = []
    for (let i = 0; i < actDom.length; i++) {
      let wHeight = document.body.scrollTop || document.documentElement.scrollTop
      domTop[i] = actDom[i].offsetTop
      if (actDom[i].offsetTop < domTop[i - 1] || 0) {
        domTop[i] = actDom[i].parentNode.offsetTop
      }
      if (actDom[i].offsetTop < domTop[i - 1] || 0) {
        domTop[i] = actDom[i].parentNode.parentNode.offsetTop
      }
      if (wHeight + window.innerHeight > domTop[i] && !actDom[i].className.match('animated')) {
        // actDom[i].classList.add('animated')
        actDom[i].className = actDom[i].className + ' animated ' + actDom[i].getAttribute('data-actcls')
        // actDom[i].classList.add(actDom[i].getAttribute('data-actcls'))
        // actDom[i].classList.add(actDom[i].getAttribute('data-actcls'))
        if (actDom[i].getAttribute('data-src')) {
          actDom[i].src = actDom[i].getAttribute('data-src')
        }
        // eslint-disable-next-line 
        if (actDom[i].getAttribute('data-lazypic') == 1) {

          let imgs = actDom[i].getElementsByTagName('img')
          for (let j = 0; j < imgs.length; j++) {
            if (imgs[j].getAttribute('data-src')) {
              imgs[j].src = imgs[j].getAttribute('data-src')
            }

          }

        }
      }
    }
  }
  render() {
    return (
      <div>
        <nav className={`navbar headnavbar navbar-light navbar-expand-md headNav ${(this.state.wtabOff || (!this.state.wtabOff && this.state.tabOff)) ? 'active' : ''}`}>
          <div className="container">
            <img src={require('../../assets/img/head_logo.png')} className="companyLogo d-none d-md-block" alt="" />
            {/* eslint-disable-next-line */}
            <a className="navbar-brand d-block d-md-none" href="javascript:;">
              {
                // eslint-disable-next-line
                this.state.pageList.map((item, index) => {
                  if (item.page === this.props.nowPage) {
                    return item.title
                  }
                })
              }
            </a>
            <button className="navbar-toggler" id="nav_show" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse flex-grow-0 navbar-collapse" id="navbarNav">

              <ul className="navbar-nav">
                {
                  this.state.pageList.map((item, index) => {
                    if (item.type !== 'dropdown') {
                      return (
                        <li className={`nav-item pr-0 pr-lg-3 pl-lg-3 pl-0 ${this.props.nowPage === item.page ? 'active' : ''}`} key={index}>
                          {/* eslint-disable-next-line */}
                          <a className="nav-link fz-18" href="javascript:;" onClick={() => this.props.goPage(item.page)}>{item.title}<span className="sr-only">(current)</span></a>
                        </li>
                      )
                    } else {
                      return (
                        <li className={`nav-item dropdown pr-0 pr-lg-3 pl-lg-3 pl-0 ${this.props.nowPage === item.page ? 'active' : ''}`} key={index}>
                          <a className="nav-link dropdown-toggle fz-18" data-toggle="dropdown" href="javascript:;" role="button" aria-expanded="false">{item.title}</a>
                          <div className="dropdown-menu fz-18" data-dismiss="modal">
                            {
                              item.chilren.map((chilren, i) => {
                                if (chilren.type === 'preview') {
                                  return (
                                    <a className="dropdown-item fz-16" data-toggle="modal"  data-target={'#'+chilren.modelId} onClick={()=>this.props.dropdownChange()} href="javascript:;" key={i}>{chilren.title}</a>
                                  )
                                } else {
                                  return (
                                    <a className="dropdown-item fz-16" href={chilren.href} target="_black" onClick={()=>this.props.dropdownChange()} key={i}>{chilren.title}</a>
                                  )
                                }
                              })
                            }
                          </div>
                        </li>
                      )
                    }
                  })
                }
              </ul>
            </div>
          </div>
        </nav>
        <div className="modal fade" style={{zIndex:10000}} id="modelId1"  aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-md modal-dialog-centered row justify-content-center">
            <div className="modal-content col-6  pr-0 pl-0">
              <div className="modal-body">
                <img className='img-thumbnail' src={require('../../assets/img/menu/zxxy-qrcode.png')} style={{width:'100%'}} />
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" style={{zIndex:10000}} id="modelId2"  aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-md modal-dialog-centered row justify-content-center">
            <div className="modal-content col-6  pr-0 pl-0">
              <div className="modal-body">
                <img className='img-thumbnail' src={require('../../assets/img/menu/qj-qrcode.png')} style={{width:'100%'}} />
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

export default Head
import React, { Component } from 'react';

class ComFri extends Component {
  render() {
    return (
      // home.css
      <div className="comFriBox pt-5 pb-3 actDom" data-actcls="fadeInUp" data-lazypic="1">
        <div className="container">
          <h6 className="fz-36 bigTitle actDom mt-5" data-actcls="fadeInUp">合作伙伴</h6>
          <p className="fz-20 text-muted text-md-center pb-3 confirm_text">我们有幸倾听各行各业各品牌企业的故事，并且致力于融入其中，为其开发有灵魂的产品。</p>
          <div className="comFri w-100 pb-5 pt-3">
          <div>
            <img src='' data-src={ require('../../assets/img/comFri/comfri1.png') } alt=""/>
            <img src='' data-src={ require('../../assets/img/comFri/comfri1.png') } alt=""/>
          </div>
          <div>
            <img src='' data-src={ require('../../assets/img/comFri/comfri2.png') } alt=""/>
            <img src='' data-src={ require('../../assets/img/comFri/comfri2.png') } alt=""/>
          </div>
          <div>
            <img src='' data-src={ require('../../assets/img/comFri/comfri3.png') } alt=""/>
            <img src='' data-src={ require('../../assets/img/comFri/comfri3.png') } alt=""/>
          </div>
          <div>
            <img src='' data-src={ require('../../assets/img/comFri/comfri4.png') } alt=""/>
            <img src='' data-src={ require('../../assets/img/comFri/comfri4.png') } alt=""/>
          </div>
          <div>
            <img src='' data-src={ require('../../assets/img/comFri/comfri5.png') } alt=""/>
            <img src='' data-src={ require('../../assets/img/comFri/comfri5.png') } alt=""/>
          </div>
          <div>
            <img src='' data-src={ require('../../assets/img/comFri/comfri6.png') } alt=""/>
            <img src='' data-src={ require('../../assets/img/comFri/comfri6.png') } alt=""/>
          </div>
          <div>
            <img src='' data-src={ require('../../assets/img/comFri/comfri7.png') } alt=""/>
            <img src='' data-src={ require('../../assets/img/comFri/comfri7.png') } alt=""/>
          </div>
          <div>
            <img src='' data-src={ require('../../assets/img/comFri/comfri8.png') } alt=""/>
            <img src='' data-src={ require('../../assets/img/comFri/comfri8.png') } alt=""/>
          </div>
          <div>
            <img src='' data-src={ require('../../assets/img/comFri/comfri9.png') } alt=""/>
            <img src='' data-src={ require('../../assets/img/comFri/comfri9.png') } alt=""/>
          </div>
          <div> 
            <img src='' data-src={ require('../../assets/img/comFri/comfri10.png') } alt=""/>
            <img src='' data-src={ require('../../assets/img/comFri/comfri10.png') } alt=""/>
          </div>
          <div> 
            <img src='' data-src={ require('../../assets/img/comFri/comfri11.png') } alt=""/>
            <img src='' data-src={ require('../../assets/img/comFri/comfri11.png') } alt=""/>
          </div>
          <div> 
            <img src='' data-src={ require('../../assets/img/comFri/comfri12.png') } alt=""/>
            <img src='' data-src={ require('../../assets/img/comFri/comfri12.png') } alt=""/>
          </div>
          <div> 
            <img src='' data-src={ require('../../assets/img/comFri/comfri13.png') } alt=""/>
            <img src='' data-src={ require('../../assets/img/comFri/comfri13.png') } alt=""/>
          </div>
          <div> 
            <img src='' data-src={ require('../../assets/img/comFri/comfri14.png') } alt=""/>
            <img src='' data-src={ require('../../assets/img/comFri/comfri14.png') } alt=""/>
          </div>
          <div> 
            <img src='' data-src={ require('../../assets/img/comFri/comfri15.png') } alt=""/>
            <img src='' data-src={ require('../../assets/img/comFri/comfri15.png') } alt=""/>
          </div>
        </div>
        </div>
        
        
      </div>
      
    )
  }
}

export default ComFri
import React, { Component } from 'react';
// import './assets/css/reset.scss'
import { Router, Route, Redirect, Switch } from "react-router-dom";
// import { createBrowserHistory } from 'history';
import { createHashHistory } from 'history';

import Home from './pages/home'
import About from './pages/about'
import Example from './pages/example'
import Product from './pages/product'
import Resolve from './pages/resolve'

import Menu from './pages/main/menu'
import Head from './pages/main/head'
import Footer from './pages/main/footer'

const history = createHashHistory();

/* eslint-disable*/
class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      nowPage: history.location.pathname === '/' ? '/bgtWeb' : history.location.pathname
    }
  }
  goPage(path) {
    if (path === this.state.nowPage) {
      return
    }
    document.body.scrollTop = document.documentElement.scrollTop = 0
    this.setState({
      nowPage: path
    })
    history.push(path)
    if (document.querySelector('.navbar-collapse.show')) {
      document.querySelector('#nav_show').click()
    }
    
  }
  dropdownChange() {
    if (document.querySelector('.navbar-collapse.show')) {
      document.querySelector('#nav_show').click()
    }
    
  }
  headload() {
    // console.log('新页面', history.location.pathname)
    
    if (history.location.pathname != this.state.nowPage) {
      this.setState({
        nowPage: history.location.pathname
      })
    }
  }
  render() {
    return (
      
      <div className="rootCon position-relative">
        <Head goPage={ (path) => this.goPage(path) } dropdownChange={()=>this.dropdownChange()} nowPage={this.state.nowPage} headload={() => this.headload()} />
        
        
        <Router history={history}>
          <Switch>
            <Route exact path="/bgtWeb" component={Home} />
            <Route path="/bgtWeb/about" component={ About } />
            <Route path="/bgtWeb/example" component={ Example } />
            <Route path="/bgtWeb/product" component={ Product } />
            <Route path="/bgtWeb/resolve" component={ Resolve } />
            <Redirect from="/" to="/bgtWeb"></Redirect>
          </Switch>
        </Router>

        {/* footer */}
        <Footer goPage={ (path) => this.goPage(path) } />

        {/* 右下角菜单 */}
        <Menu />
      </div>
     
    )
  }
}

export default App;

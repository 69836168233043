import React, { Component } from 'react';
import '../assets/css/product.scss'
// import ComFri from './main/comFri'

class Product extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  goPage(path) {
    this.props.history.push(path)
    document.body.scrollTop = document.documentElement.scrollTop = 0
  }
  render() {
    return (
      <div className="product">
        <div className="banner">
          <img src={require('../assets/img/product/product-banner.png')} alt="" />
          <div className="banner_conBox pt-5">
            <div className="banner_con pt-5 actDom" data-actcls="fadeInUp">
              <h6 className="fz-50 text-light pt-5">产品不断创新 服务永无止境</h6>
              <p className="fz-36 text-light">产品+服务+资源整合，合作带给客户增值</p>
            </div>
          </div>
        </div>

        {/* 产品优势 */}
        <div className="container">
          <h6 className="fz-36 bigTitle actDom" data-actcls="fadeInUp">数字化服务</h6>
          <div className="fz-18 text-muted text-md-center pb-5">
            <p className="rl_sttext mx-auto actDom" data-actcls="fadeInUp">框架+应用的技术架构实施,为客户提供极高性价比的产品和服务方案;并且具备独立性、安全性、兼容性、可扩展性;为客户交付标准化、协同化，实时化，智能化</p>
          </div>

          


        </div>
        <div className="myContainer">
          <div className="rl_svg">
              <div className="row actDom" data-actcls="fadeInUp">
                <div className="svg_l col-12 col-lg-6">
                  <div className="svg_l_t">
                    <div>
                      <svg>
                        <polygon points="85,0 170,50 170,150 85,200 0,150 0,50" />
                        <text x="50%" y="55%" className="fz-24">移动互联网</text>
                      </svg>
                      <svg>
                        <polygon points="85,0 170,50 170,150 85,200 0,150 0,50" />
                        <text x="50%" y="55%" className="fz-24">大数据</text>
                      </svg>
                    </div>
                    
                  </div>
                  <div className="svg_l_b">
                    <div>
                      <svg>
                        <polygon className="black_sharp" points="85,0 170,50 170,150 85,200 0,150 0,50" />
                        <text x="50%" y="55%" className="fz-24">物联网</text>
                      </svg>
                      <svg>
                        <polygon className="black_sharp" points="85,0 170,50 170,150 85,200 0,150 0,50" />
                        <text x="50%" y="55%" className="fz-24">区块链</text>
                      </svg>
                    </div>
                    
                  </div>
                </div>
                <div className="svg_r col-12 col-lg-6">
                  <div className="svg_r_t">
                    <div>
                      <svg>
                        <polygon points="85,0 170,50 170,150 85,200 0,150 0,50" />
                        <text x="50%" y="55%" className="fz-24">元宇宙</text>
                      </svg>
                      <svg>
                        <polygon points="85,0 170,50 170,150 85,200 0,150 0,50" />
                        <text x="50%" y="55%" className="fz-24">人工智能</text>
                      </svg>
                    </div>
                    
                  </div>
                  <div className="svg_r_b">
                    <div>
                      <svg>
                        <polygon className="black_sharp" points="85,0 170,50 170,150 85,200 0,150 0,50" />
                        <text x="50%" y="55%" className="fz-24">新零售</text>
                      </svg>
                      <svg>
                        <polygon className="black_sharp" points="85,0 170,50 170,150 85,200 0,150 0,50" />
                        <text x="50%" y="55%" className="fz-24">数据资产</text>
                      </svg>
                    </div>
                    
                  </div>
                </div>
              </div>
              
            </div>
        </div>

        {/* 金融科技 example */}
        <div className="container">
          <h6 className="fz-36 bigTitle actDom" data-actcls="fadeInUp">产业运营服务</h6>
          <p className="fz-20 text-muted text-center actDom" data-actcls="fadeInUp">运用“数字化+服务资源+招商能力+产业数字化能力+园区运营”的合力协同协同，打造“政府主导+市场驱动+资源整合与落地+企业联动”的产业运营服务新模式。</p>
          <ul className="ex_pro row">
            <li className="col-12 col-md-6 actDom" data-actcls="bounceInLeft">
              <div className="w-100">
                <h6 className="fz-22">数字要素储备</h6>
                <p className="fz-18">提供数字化招商系统,经济圈数字产业平台、辖区企业生命周期跟踪及关怀系统,打造新质生产力</p>
              </div>
              <img src={require("../assets/img/product/ruanjian@2x.png")} alt="" />
              <div className="ex_pro_count"><h6 className="fz-80">1</h6></div>
            </li>
            <li className="col-12 col-md-6 actDom" data-actcls="bounceInRight">
              <img src={require("../assets/img/product/anquanfangwei@2x.png")} alt=""/>
              <div className="ex_pro_count"><h6 className="fz-80">2</h6></div>
              <div className="w-100">
                <h6 className="fz-22">资源及能力</h6>
                <p className="fz-18">依托金融机构，商业服务机构，园区，高校、研究院等机构、媒体等资源，融合对企服务的链化服务等运营能力</p>
              </div>
            </li>
            <li className="col-12 col-md-6 actDom tDely-01" data-actcls="bounceInLeft">
              <div className="w-100">
                <h6 className="fz-22">市场</h6>
                <p className="fz-18">通过整合与优化市场资源，为业主提供全方位赋能，提升资产价值与运营效率，实现可持续发展</p>
              </div>
              <img src={require("../assets/img/product/xiangmushishi@2x.png")} alt="" />
              <div className="ex_pro_count"><h6 className="fz-80">3</h6></div>
            </li>
            <li className="col-12 col-md-6 actDom tDely-01" data-actcls="bounceInRight">
              <img src={require("../assets/img/product/yunying@2x.png")} alt="" />
              <div className="ex_pro_count"><h6 className="fz-80">4</h6></div>
              <div className="w-100">
                <h6 className="fz-22">资金</h6>
                <p className="fz-18">以资金为纽带，整合产业引导基金以及社会资本，为客户提供资金支持与优化配置，助力辖区企业成长和项目落地</p>
              </div>
            </li>
          </ul>
        </div>
        {/* 智慧园区  home.css */}
        <div className="position-relative pb-5 mt-5">
          <img src={require('../assets/img/product/product-png.png')} alt="" className="rl_system_bg" />
            <div className='container rl_system_con'>
              <div className="rl_system_title fz-36 pt-5 pb-5 actDom" data-actcls="fadeInUp">智慧科技产品/服务</div>

              <div className="home_step actDom" data-actcls="fadeInUp">
                
                <div className="home_step_icon" onClick={ () => this.goPage('/bgtWeb/resolve') }>
                  <img src={ require('../assets/img/product/yuanqu@2x.png') } alt=""/>
                </div>
                <div className="fz-50">
                +
                </div>
                <div className="home_step_icon" onClick={ () => this.goPage('/bgtWeb/resolve') }>
                  <img src={ require('../assets/img/product/sheshi@2x.png') } alt=""/>
                </div>
                <div className="fz-50">
                  +
                </div>
                <div className="mr-0 home_step_icon mr-lg-4" onClick={ () => this.goPage('/bgtWeb/resolve') }>
                  <img src={ require('../assets/img/product/jingxi@2x.png') } alt=""/>
              </div> 
              

              <div className="ml-0 home_step_icon ml-lg-4" onClick={ () => this.goPage('/bgtWeb/resolve') }>
                  <img src={ require('../assets/img/product/kefu@2x.png') } className="" alt=""/>
                </div>
                <div className="fz-50">
                +
                </div>
                <div className="home_step_icon" onClick={ () => this.goPage('/bgtWeb/resolve') }>
                  <img src={ require('../assets/img/product/qiye@2x.png') } alt=""/>
                </div>
                <div className="fz-50">
                  +
                </div>
                <div className="home_step_icon" onClick={ () => this.goPage('/bgtWeb/resolve') }>
                  <img src={ require('../assets/img/product/chixu@2x.png') } alt=""/>
                </div> 
              </div>
              
              <p className="text-light fz-20 pt-4 text-center pb-3 actDom" data-actcls="fadeInUp">自主研发园区数字化操作系统(DPOS),满足园区经营中对于数字化各类需求</p>
          </div>
        </div>
        {/* 搜雪数字园区系统的优势 */}
        <div className="container">
          <h6 className="fz-36 bigTitle actDom" data-actcls="fadeInUp">搜雪数字园区系统的优势</h6>
          <ul className="ex_pro souxue_box row d-flex justify-content-between">
            <li className="col-12 col-md-4 actDom" data-actcls="bounceInLeft">
              <div className="w-100">
                <h6 className="fz-22">独立性</h6>
                <p className="fz-18">代码无加密交付客户,为园区构建统一的数据,管理中心平台,以及扩展各类应用</p>
              </div>
            </li>
            <li className="col-12 col-md-4 actDom" data-actcls="bounceInRight">
              <div className="w-100">
                <h6 className="fz-22">适配性</h6>
                <p className="fz-18">操作系统+应用模式,满足单个园区,尤其是支持集团型园区,进行统一管理</p>
              </div>
            </li>
            <div className="col-12 col-md-12 actDom d-flex justify-content-center " data-actcls="fadeInUp">
              <img className="souxue_qrcode col-6 col-md-3"  src={ require('../assets/img/souxue_qrcode.png') } alt=""/>
            </div>
            <li className="col-12 col-md-4 actDom tDely-01" data-actcls="bounceInLeft">
              <div className="w-100">
                <h6 className="fz-22">无缝支撑</h6>
                <p className="fz-18">基于数据,开发更多业务逻辑.从而解决园区管理、经营、运营等全方位的需求</p>
              </div>
            </li>
            <li className="col-12 col-md-4 actDom tDely-01" data-actcls="bounceInRight">
              <div className="w-100">
                <h6 className="fz-22">联运性</h6>
                <p className="fz-18">将用数字化技术,联合巴瓜潭产业运营团队,与客户联合运营,打造运营生态,提供增收运营系统,共享数字化带来的增值收益</p>
              </div>
            </li>
          </ul>
        </div>
        {/* 软件定制 */}
        <div className="container">
          <h6 className="fz-36 bigTitle actDom" data-actcls="fadeInUp">社会化数字业务</h6>
          <p className="fz-20 text-muted text-center actDom" data-actcls="fadeInUp">通过数字技术和园区平台，促进巴瓜潭生态的互动与合作，增强用户之间的连接，促进社会化消费和服务创新,推动信息、资源和服务的共享与流通。包括社交媒体、在线社区、电子商务、数字营销等，通过数据分析和用户参与，提升用户体验，为客户实现商业价值。</p>
          <ul className="pd_software actDom" data-actcls="fadeInUp">
            <li onClick={ () => this.goPage('/bgtWeb/example') }>
              <div className="pd_software_img"></div>
              <div className="pd_software_title fz-20">数字化营销</div>
              {/* <p className="fz-14">让营销变简单 , 创造互动打造微信营销一体化方案</p> */}
            </li>
            <li onClick={ () => this.goPage('/bgtWeb/example') }>
              <div className="pd_software_img"></div>
              <div className="pd_software_title fz-20">微信生态</div>
              {/* <p className="fz-14">让营销变简单 , 创造互动打造微信营销一体化方案</p> */}
            </li>
            <li onClick={ () => this.goPage('/bgtWeb/example') }>
              <div className="pd_software_img"></div>
              <div className="pd_software_title fz-20">电商服务</div>
              {/* <p className="fz-14">让营销变简单 , 创造互动打造微信营销一体化方案</p> */}
            </li>
            <li onClick={ () => this.goPage('/bgtWeb/example') }>
              <div className="pd_software_img"></div>
              <div className="pd_software_title fz-20">嵌入式经营</div>
              {/* <p className="fz-14">让营销变简单 , 创造互动打造微信营销一体化方案</p> */}
            </li>
            <li onClick={ () => this.goPage('/bgtWeb/example') }>
              <div className="pd_software_img"></div>
              <div className="pd_software_title fz-20">数字消费实验室</div>
              {/* <p className="fz-14">让营销变简单 , 创造互动打造微信营销一体化方案</p> */}
            </li>
          </ul>
        </div>
          
        {/* <ComFri /> */}
      </div>
    )
  }
}

export default Product
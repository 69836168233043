import React, { Component } from 'react';
import '../assets/css/home.scss'
// import ComFri from './main/comFri'

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      homeNum: 0
    }
  }
  componentDidMount() {
    // this.scrollFun()
  }
  componentWillUnmount() {
    if (this.homeTimer) {
      clearInterval(clearInterval())
    }
    this.setState = (state, callback) => {
      return;
    };
  }
  // scrollFun() {
  //   let actDomNum = document.querySelector('.actDomNum')
  //   let homeNum = 0
  //   window.addEventListener('scroll', () => {
  //     let wH = document.body.scrollTop || document.documentElement.scrollTop
  //     if (actDomNum.offsetTop <= wH + window.innerHeight && homeNum === 0) {
  //       homeNum++
  //       this.homeTimer = setInterval(() => {
  //         homeNum++
  //         if (homeNum < 16) {
  //           this.setState({
  //             homeNum
  //           })
  //         } else {
  //           clearInterval(this.homeTimer)
  //         }
          
  //       }, 100)
  //     }
  //   })
  // }
  goPage(path) {
    this.props.history.push(path)
    document.body.scrollTop = document.documentElement.scrollTop = 0
  }
  render() {
    return (
      <div className="home">
        <div className="banner">
          <img src={require('../assets/img/home/home-banner-one.png')} alt="" />
          <div className="banner_conBox banner_home pl-md-5">
            <div className="banner_con container pt-50 actDom" data-actcls="fadeInUp">
              <img className="home_logo" src={require('../assets/img/home/logo.png')} />
              <h6 className="fz-50 fz-home-50 banner_con_title">
                巴瓜潭诞生以来<br/>我们一直践行产业数字化<br/>数字产业化,提供优质的数字化解决方案
              </h6>
              <div className="fz-home-20">DIGITAL INDUSTRY</div>
            </div>
            <div className="home-bottom  actDom">BAGUATAN DIGITAL INDUSTRY</div>
          </div>
        </div>

        
        {/* 公司业务 */}
        <div className="container">
          <h6 className="fz-36 bigTitle actDom" data-actcls="fadeInUp">公司主营业务</h6>
          <p className="fz-20 text-muted text-center actDom" data-actcls="fadeInUp">银行等金融机构的软件外包定制等服务，并且依托银行的资源进行智慧科技类的软件定制服务。</p>

          <ul className="home_server row actDom" data-actcls="fadeInUp">
            <li className="col-lg-4 col-12" onClick={ () => this.goPage('/bgtWeb/product') }>
              <div>
                <div className="home_server_img"></div>
                <h6 className="fz-28">数字化解决方案</h6>
                <p className="fz-20">十年磨一剑,我们将所有的技术和经验积累,打造了OS级产品: “星数引擎,赋能于客户。</p>
              </div>
            </li>
            <li className="col-lg-4 col-12" onClick={ () => this.goPage('/bgtWeb/product') }>
              <div>
                <div className="home_server_img"></div>
                <h6 className="fz-28">数字化园区</h6>
                <p className="fz-20">数字化向“泛园区”这个垂直行业纵深实践,为园区用户解决适配度稿的数字化解决方案,从而为园区客户解决降本、增效、增收等问题。</p>
              </div>
            </li>
            <li className="col-lg-4 col-12" onClick={ () => this.goPage('/bgtWeb/product') }>
              <div>
                <div className="home_server_img"></div>
                <h6 className="fz-28">产业运营服务</h6>
                <p className="fz-20">基于数字化的技术、经历和客户资源的积累,我们为政府以及园区助力招商运营工作.用产业思维,为客户进行服务。</p>
              </div>
            </li>
          </ul>
        </div>

 
        {/* 服务流程 */}
        <div className="position-relative pb-5 mt-5">
          <img src={require('../assets/img/home/home-two.png')} alt="" className="rl_system_bg" />
            <div className='container rl_system_con'>
              <div className="rl_system_title fz-36 pt-5 pb-5 actDom" data-actcls="fadeInUp">服务流程</div>

              <div className="home_step actDom" data-actcls="fadeInUp">
                <div className="home_step_icon" onClick={ () => this.goPage('/bgtWeb/example') }>
                  <img src={ require('../assets/img/home/zixun@2x.png') } alt=""/>
                </div>
                <div className="fz-50">
                  ＋
                </div>
                <div className="home_step_icon" onClick={ () => this.goPage('/bgtWeb/example') }>
                  <img src={ require('../assets/img/home/fangan@2x.png') } alt=""/>
                </div>
                <div className="fz-50 d-none d-md-block">
                  ＋
                </div>
                <div className="home_step_icon" onClick={ () => this.goPage('/bgtWeb/example') }>
                  <img src={ require('../assets/img/home/yingyong@2x.png') } alt=""/>
                </div> 
                <div className="fz-50">
                  ＋
                </div>
                <div className="home_step_icon" onClick={ () => this.goPage('/bgtWeb/example') }>
                  <img src={ require('../assets/img/home/pingtai@2x.png') } alt=""/>
                </div>
              </div>
              
              <p className="text-light fz-20 pt-4 text-center pb-3 actDom" data-actcls="fadeInUp">沟通需求,提供建议,形成方案,签署协议,到工作实践,实施交付,运行/运营维护。</p>
            </div>
        </div>



        {/* 优势 */}
        {/* <div className="container home_good">
          <h6 className="fz-36 bigTitle actDom" data-actcls="fadeInUp">我们的优势</h6>
          <p className="fz-20 text-muted text-md-center pb-3 actDom" data-actcls="fadeInUp">整合资源 厚积薄发</p>

          <div className="home_good_list1">
            <div data-actcls="bounceInLeft" data-lazypic="1" className="actDom tDely-2" onClick={ () => this.goPage('/bgtWeb/about') }>
              <p><span className="fz-24">优秀人才</span></p> 
              <img src='' data-src={ require('../assets/img/home/jishu.png') }   alt=""/>
            </div>
            <div data-actcls="bounceInLeft" data-lazypic="1" className="actDom tDely-1" onClick={ () => this.goPage('/bgtWeb/about') }>
              <p><span className="fz-24">领先技术</span></p> 
              <img src=''   data-src={ require('../assets/img/home/ren.png') } alt=""/>
            </div>
            <div data-actcls="bounceInLeft" data-lazypic="1" className="actDom" onClick={ () => this.goPage('/bgtWeb/about') }>
              <p><span className="fz-24">雄厚实力</span></p> 
              <img src=''  data-src={ require('../assets/img/home/shili.png') } alt=""/>
            </div>
          </div>
          <div className="home_good_list2 pb-5">
            <div data-actcls="bounceInRight" data-lazypic="1" className="actDom tDely-2" onClick={ () => this.goPage('/bgtWeb/about') }>
              <p><span className="fz-24">海量数据</span></p> 
              <img src='' data-src={ require('../assets/img/home/shuju.png') } alt=""/>
            </div>
            <div data-actcls="bounceInRight" data-lazypic="1" className="actDom tDely-1" onClick={ () => this.goPage('/bgtWeb/about') }>
              <p><span className="fz-24">丰富场景</span></p> 
              <img src='' data-src={ require('../assets/img/home/changjing.png') } alt=""/>
            </div>
            <div data-actcls="bounceInRight" data-lazypic="1" className="actDom" onClick={ () => this.goPage('/bgtWeb/about') }>
              <p><span className="fz-24">安全场景</span></p> 
              <img src='' data-src={ require('../assets/img/home/anquan.png') } alt=""/>
            </div>
           </div>


           <div className="text-primary fz-36 text-center pt-5 actDomNum actDom" data-actcls="fadeInUp">
            已服务于<span className="fz-50 font-weight-bold">{ this.state.homeNum }</span>家产业园区
          </div>
          
        </div> */}

        
        {/* <ComFri /> */}
        
      </div>
    )
  }
}

export default Home